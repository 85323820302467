/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
}
.tiptap p {
  margin: 0;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.prose {
  background-color: #FBFCFE;
  color: #32383E;
  padding: 0px 12px;
  line-height: 34px;
  font-size: medium;
  border: 1px;
  border-style: solid;
  border-color: var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)));
  border-radius: 6px;
  box-shadow: 0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);
  text-align: left;
  overflow-y: auto;
  &:focus {
    outline: 2px solid var(--joy-palette-primary-500) !important;
    outline-offset: -2px;
  }
}


.prose-area {
  min-height: 150px;
  height: 300px;
  line-height: 34px;
  resize: vertical;
}


.prose-error {
  border: 2px;
  border-style: solid;
  border-color: #d32f2f;
  &:hover {
    border-color: #d32f2f;
  }
  border-radius: 4px;
  text-align: left;
  overflow-y: auto;
}

.mention {
  display: inline-block;
  text-align: left;
  font-size: 14px;
  background: #eee;
  line-height: 1.8;
  letter-spacing: 0;
  font-weight: 600;
  padding: 0px 4px;
  color: #1C2025;
  background-color: #F3F6F9;
  border: 1px solid;
  border-color: #CDD7E1;
  border-radius: 5px;
  &.is-selected {
    border-color: #CDD7E1;
  }
  &:hover {
    background-color: #e5e9ed;
    cursor: pointer;
  }
}
