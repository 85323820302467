body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    /* -webkit-print-color-adjust: exact; */
    background-color: 'transparent' !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
    page-break-after: always;
  }
}

@page {
  size: auto;
  margin: 5mm;
}

@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Serif+Text:ital@0;1&family=Delicious+Handrawn&family=Engagement&family=Fredoka:wght@300..700&family=Galada&family=Gluten:wght@100..900&family=Love+Light&family=Mea+Culpa&family=Noto+Sans+Math&family=Oregano:ital@0;1&family=Patrick+Hand&family=Rancho&family=Rouge+Script&family=Satisfy&family=Whisper&family=Yesteryear&family=Yuji+Hentaigana+Akebono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=DM+Serif+Text:ital@0;1&family=Delicious+Handrawn&family=Engagement&family=Fredoka:wght@300..700&family=Gluten:wght@100..900&family=Love+Light&family=Mea+Culpa&family=Mochiy+Pop+One&family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+Math&family=Oregano:ital@0;1&family=Patrick+Hand&family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&family=Rancho&family=Rouge+Script&family=Satisfy&family=Whisper&family=Yesteryear&family=Yuji+Hentaigana+Akebono&display=swap');