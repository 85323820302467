@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Serif+Text:ital@0;1&family=Delicious+Handrawn&family=Engagement&family=Fredoka:wght@300..700&family=Galada&family=Gluten:wght@100..900&family=Love+Light&family=Mea+Culpa&family=Noto+Sans+Math&family=Oregano:ital@0;1&family=Patrick+Hand&family=Rancho&family=Rouge+Script&family=Satisfy&family=Whisper&family=Yesteryear&family=Yuji+Hentaigana+Akebono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=DM+Serif+Text:ital@0;1&family=Delicious+Handrawn&family=Engagement&family=Fredoka:wght@300..700&family=Gluten:wght@100..900&family=Love+Light&family=Mea+Culpa&family=Mochiy+Pop+One&family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+Math&family=Oregano:ital@0;1&family=Patrick+Hand&family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&family=Rancho&family=Rouge+Script&family=Satisfy&family=Whisper&family=Yesteryear&family=Yuji+Hentaigana+Akebono&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F9FA;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    /* -webkit-print-color-adjust: exact; */
    background-color: 'transparent' !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
    page-break-after: always;
  }
}

@page {
  size: auto;
  margin: 5mm;
}
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
}
.tiptap p {
  margin: 0;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.prose {
  background-color: #FBFCFE;
  color: #32383E;
  padding: 0px 12px;
  line-height: 34px;
  font-size: medium;
  border: 1px;
  border-style: solid;
  border-color: var(--variant-outlinedBorder, var(--joy-palette-neutral-outlinedBorder, var(--joy-palette-neutral-300, #CDD7E1)));
  border-radius: 6px;
  box-shadow: 0 0 #000,  0px 1px 2px 0px rgba(21,21,21, 0.08);
  text-align: left;
  overflow-y: auto;
  &:focus {
    outline: 2px solid var(--joy-palette-primary-500) !important;
    outline-offset: -2px;
  }
}


.prose-area {
  min-height: 150px;
  height: 300px;
  line-height: 34px;
  resize: vertical;
}


.prose-error {
  border: 2px;
  border-style: solid;
  border-color: #d32f2f;
  &:hover {
    border-color: #d32f2f;
  }
  border-radius: 4px;
  text-align: left;
  overflow-y: auto;
}

.mention {
  display: inline-block;
  text-align: left;
  font-size: 14px;
  background: #eee;
  line-height: 1.8;
  letter-spacing: 0;
  font-weight: 600;
  padding: 0px 4px;
  color: #1C2025;
  background-color: #F3F6F9;
  border: 1px solid;
  border-color: #CDD7E1;
  border-radius: 5px;
  &.is-selected {
    border-color: #CDD7E1;
  }
  &:hover {
    background-color: #e5e9ed;
    cursor: pointer;
  }
}

.items {
  position: relative;
  border-radius: 0.5rem;
  padding: 0 0.5em;
  border-style: solid;
  border-color: #CDD7E1;
  background: #FFF;
  color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  height: 350px;
  width: 300px;
  font-size: 0.9rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1),
  ;
}

.item {
  display: block;
  width: 100%;
  text-align: left;
  font-size: small;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
  letter-spacing: 0;
  font-family: Inter;
  font-weight: 600;
  font-size: 1em;
  padding: 6px 10px;
  color: #1C2025;
  background-color: #FBFCFE;
  border: 1px solid;
  border-color: #CDD7E1;
  border-radius: 5px;
  &.is-selected {
    outline: 2px solid var(--joy-palette-primary-500);
    outline-offset: -2px;
  }
  &:hover {
    background-color: #fff;
    outline: 2px solid var(--joy-palette-primary-500);
    outline-offset: -2px;
  }
}
