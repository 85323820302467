.items {
  position: relative;
  border-radius: 0.5rem;
  padding: 0 0.5em;
  border-style: solid;
  border-color: #CDD7E1;
  background: #FFF;
  color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
  overflow-x: hidden;
  height: 350px;
  width: 300px;
  font-size: 0.9rem;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1),
  ;
}

.item {
  display: block;
  width: 100%;
  text-align: left;
  font-size: small;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
  letter-spacing: 0;
  font-family: Inter;
  font-weight: 600;
  font-size: 1em;
  padding: 6px 10px;
  color: #1C2025;
  background-color: #FBFCFE;
  border: 1px solid;
  border-color: #CDD7E1;
  border-radius: 5px;
  &.is-selected {
    outline: 2px solid var(--joy-palette-primary-500);
    outline-offset: -2px;
  }
  &:hover {
    background-color: #fff;
    outline: 2px solid var(--joy-palette-primary-500);
    outline-offset: -2px;
  }
}